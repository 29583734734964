(function ($) {
    var $tabsSummary = $('.tabs summary');

    $tabsSummary.click(function () {
        $(this).closest('.tabs').find('details').removeAttr('open');
    });

    var $tabs = $('.tabs details');

    function calculateTabHeight($tab) {
        if ($tab.attr('open')) {
            var $tabHeight = $tab.find('summary').outerHeight();
            var $contentHeight = $tab.find('.tab-content').outerHeight();
            $tab.find('.tab-content').css('top:' + $tabHeight + 'px');
            $tab.closest('.tab-container').height($tabHeight + $contentHeight);
        }
    }

    function resizeAllOpenTabs() {
        $tabs.each(function (index) {
            var $el = $(this);
            calculateTabHeight($el);
        });
    }

    resizeAllOpenTabs();

    //This adds keyboard function that pressing an arrow left or arrow right from the tabs toggle the tabs.
    $tabsSummary.keydown(function (ev) {
        if (ev.which === 37) {
            $(this).closest('details').prev().find('summary').click().focus();
        } else if (ev.which === 39) {
            $(this).closest('details').next().find('summary').click().focus();
        }
    });

    // @todo - prevent everything below from executing twice on tab change
    $tabs.attrchange({
        trackValues: true, /* Default to false, if set to true the event object is
                updated with old and new value.*/
        callback: function (event) {
            var $tabContainer = $(this).parent();

            $tabsSummary.click(function () {
                $(this).closest('.tabs').find('details').removeAttr('open');
            });
            if (event.attributeName === 'open') {
                var $tab = $(event.target);
                calculateTabHeight($tab);
            }
            showHideTabNav($tabContainer);
            moveActiveTabIntoView($tabContainer);
        }
    });

    $('.tab-container').each(function() {
        showHideTabNav($(this));
        moveActiveTabIntoView($(this));
        var $tabs = $(this).find('.tab');

        $(this).find('.tab-nav-right').click(function (event) {
            event.preventDefault();
            $tabs.filter('[open]').find('summary').closest('details').next().find('summary').click().focus();
        });

        $(this).find('.tab-nav-left').click(function (event) {
            event.preventDefault();
            $tabs.filter('[open]').find('summary').closest('details').prev().find('summary').click().focus();
        });
    });

    function moveActiveTabIntoView($tabContainer) {

        var $activeTab = $tabContainer.find('.tab[open]');
        var $tabs = $tabContainer.find('.tab');

        if ($activeTab.length) {
            var containerLeft = $tabContainer.offset().left;
            var containerRight = containerLeft + $tabContainer.outerWidth();
            var currentOffset = parseInt($tabContainer.data('tab-offset'));
            var tabLeft = $activeTab.offset().left + currentOffset;
            var tabRight = tabLeft + $activeTab.outerWidth();
            var $navRight = $tabContainer.find('.tab-nav-right');
            var $navLeft = $tabContainer.find('.tab-nav-left');
            var navRightWidth = $navRight.is(':hidden') ? 0 : $navRight.outerWidth();
            var navLeftWidth = $navLeft.is(':hidden') ? 0 : $navLeft.outerWidth();
            var rightOverlap = tabRight + navRightWidth - containerRight;
            var leftOverlap = containerLeft + navLeftWidth - tabLeft;
            var currentOffset = parseInt($tabContainer.data('tab-offset'));

            if (rightOverlap > 0) {
                var newOffset = Math.round(currentOffset - rightOverlap).toString();
                $tabContainer.data('tab-offset', newOffset);
                $tabs.find('summary').css('transform', `translateX(${newOffset}px)`);
            }
            if (leftOverlap > 0) {
                var newOffset = Math.round(currentOffset + leftOverlap).toString();
                $tabContainer.data('tab-offset', newOffset);
                $tabs.find('summary').css('transform', `translateX(${newOffset}px)`);
            }
        }
    }

    function clearTabOffset($tabContainer) {
        $tabContainer.find('summary').css('transform', 'translateX(0)');
    }

    function showHideTabNav($tabContainer) {
        if ($tabContainer.find('.tab[open]').length) {
            var totalTabWidth = 0;
            var $tabs = $tabContainer.find('.tab');
            var $leftNav = $tabContainer.find('.tab-nav-left');
            var $rightNav = $tabContainer.find('.tab-nav-right');

            $tabs.each(function () {
                totalTabWidth += Math.round($(this).outerWidth());
            });
            if (Math.round($tabContainer.outerWidth()) < totalTabWidth) {
                if (!$tabs.first().attr('open')) {
                    $leftNav.show();
                } else {
                    $leftNav.hide();
                }
                if (!$tabs.last().attr('open')) {
                    $rightNav.show();
                } else {
                    $rightNav.hide();
                }
            } else {
                $leftNav.hide();
                $rightNav.hide();
                clearTabOffset($tabContainer);
            }
        }
    }

    $(window).resize(function() {
        $('.tab-container').each(function () {
            showHideTabNav($(this));
            moveActiveTabIntoView($(this));
            resizeAllOpenTabs();
        });
    });


})(jQuery);
