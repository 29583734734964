(function ($) {
    $(document).ready(function () {
        $('.tag-dropdown-filter__tag.current').click(function(evt) {
            if (!window.matchMedia('(min-width: 62rem)').matches) {
                evt.preventDefault();
                $(this).closest('.tag-dropdown-filter__list').toggleClass('open');
            }
        });
    });
})(jQuery);
