// NodeList forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

!(function () {

    // Open toggle function
    //
    // Usage:
    // - add `data-open-toggle="group-name"` to a toggle button
    // - add `data-open-group="group-name"` to the element you want to toggle
    // - add `data-single-open-group="group-name"` to all elements that should only
    //   allow a single item of the group to be open at once (closes others
    //   in the group when one opens).
    //
    // Result:
    // The element with `data-open-toggle` toggles 'open' class on all elements
    // with `data-open-group` matching value, and closes all elements with the
    // same `data-single-open-group` as the element being opened/closed.
    //
    let toggles = document.querySelectorAll('[data-open-toggle]');

    // toggle the open class on mobile menu button

    $('.menu-toggle').on('click tap touch', function() {
        $(this).toggleClass('open');
    });

    if (toggles) {
        toggles.forEach((toggle) => {
            let openGroup = document.querySelectorAll(`[data-open-group=${toggle.dataset.openToggle}]`);
            if (openGroup) {
                toggle.addEventListener('click', (event) => {
                    event.preventDefault();
                    openGroup.forEach((target) => {
                        target.classList.toggle('open');
                        let singleOpenGroup = document.querySelectorAll(`[data-single-open-group=${target.dataset.singleOpenGroup}]`);
                        if (singleOpenGroup) {
                            singleOpenGroup.forEach((single) => {
                                if (!target.isSameNode(single)) {
                                    single.classList.remove('open');
                                }
                            });
                        }
                    });
                });
            }
        });
    }
}());
